import "lazysizes";
// import Scrollbar from "smooth-scrollbar";
// import BezierEasing from "bezier-easing";

// SCROLL
// -- Init
var scaleratio = $(window).outerHeight() / 1000;
var aniComplete = true;

$(".scrollLink").on("click", function (e) {
  e.preventDefault();
  if (frameSwipe.isEndAnimate) {
    frameSwipe.isEndAnimate = false;
    frameSwipe.index = $(this).data("index");
    var scrollTo;

    if (!viewportIsMobile($("body").outerWidth())) {
      scrollTo = $($(this).data("scroll-link")).offset().top;
    } else {
      scrollTo = $($(this).data("scroll-link")).offset().top - 100;
    }

    $("html").animate(
      {
        scrollTop: scrollTo,
      },
      600,
      function () {
        setTimeout(() => {
          frameSwipe.isEndAnimate = true;
        }, frameSwipe.animateEndDelay);
      }
    );
  }
});

function viewportIsMobile(width) {
  return width < 1025;
}

import Swiper from "Swiper";
import "swiper/css/swiper.min.css";
var f3 = new Swiper(".f3-swiper", {
  slidesPerView: 1,
  pagination: {
    el: ".f3-pagination",
    clickable: true,
  },
});
