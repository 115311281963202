import "./components/_scaleRoot";
$(".main-landing .inner ").scaleRoot({
  scaleMode: viewportIsMobile($("body").outerWidth())
    ? "containWidth"
    : "containWidth",
});

// $(".outer--popup-iframe .inner ").css({
//     height: (viewportIsMobile($("body").outerWidth())) ? 'containWidth' : 'cover'
// });
import MobileDetect from "mobile-detect";

var md = new MobileDetect(window.navigator.userAgent);

if ($(".getLink").length > 0) {
  switch (md.os()) {
    case "AndroidOS":
      // $(".getLink").addClass("open-download-and");
      // $(".open-download-and").click(function (e) {
      //     e.preventDefault();

      //     $(".popup--download-android").addClass("active");
      // });
      // break;
      $(".getLink").attr("href", $(".getLink-and").attr("href"));
      break;
    case "iOS":
      $(".getLink").attr("href", $(".getLink-ios").attr("href"));
      break;
    default:
  }
}

function viewportIsMobile(width) {
  return width < 1025;
}

import "./components/_lightbox";

import "./components/_control-side";

// import "./components/_count-member";
// import "./components/_luu-danh";
// import "./components/_fb-share";
