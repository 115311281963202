var close = false;
$(".close-side").click(function (e) {
  e.preventDefault();
  if (!close) {
    $(this).addClass("toOpen");
    $(".aside-right,.rightbar").addClass("closeAsideright");
    close = true;
  } else {
    $(this).removeClass("toOpen");
    $(".aside-right,.rightbar").removeClass("closeAsideright");
    close = false;
  }
});

var closeNav = false;
$(".open-nav").click(function (e) {
  e.preventDefault();
  if (!closeNav) {
    $(this).addClass("toClose");
    $(".sub-nav-mb").addClass("Open");
    closeNav = true;
  } else {
    $(this).removeClass("toClose");
    $(".sub-nav-mb").removeClass("Open");
    closeNav = false;
  }
});
var showdown = false;
$(".btn-show-down").click(function (e) {
  e.preventDefault();
  if (!showdown) {
    $(this).addClass("active");
    $(".logout").addClass("active");
    showdown = true;
  } else {
    $(this).removeClass("active");
    $(".logout").removeClass("active");
    showdown = false;
  }
});
